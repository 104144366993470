import { WindowService } from "./WindowService";
import { UrlService } from "./UrlService";

export class ProjectService {
  /**
   * Метод для отримання projectID з window або поточного URL
   * @returns projectID як число або null
   */
  static getProjectID(): number | null {
    // Перевіряємо спочатку у window
    const projectIDFromWindow = WindowService.getProjectIDFromWindow();
    if (projectIDFromWindow !== null) {
      return projectIDFromWindow;
    }

    // Якщо у window немає, перевіряємо в URL
    return UrlService.getProjectIDFromUrl();
  }
}
