export class WindowService {
  /**
   * Метод для перевірки, чи передано projectID у глобальному об'єкті window
   * @returns projectID як число або null
   */
  static getProjectIDFromWindow(): number | null {
    const projectID = (window as any)?.projectID;
    return typeof projectID === "number" ? projectID : null;
  }
}
