import store from "../../redux/store";
import { ProjectService } from "../../services/ProjectService";

export function genDataSceneToSend(body: any) {
  let ProjectID = 1;

  try {
    const projectID = ProjectService.getProjectID();
    if (projectID !== null) {
      ProjectID = projectID;
    } else {
      console.error("Project ID not found in window or URL. Default projectID=1 will be used.");
    }
  } catch (error: any) {
    console.error(error.message);
  }

  const state = store.getState();
  const result: any = {
    stageId: 1,
    projectId: ProjectID,
  };
  const productsArr: any = [];
  let cameraParam: any = {};
  const products = state.ui.products;

  JSON.parse(body).map((item: any, index: number) => {
    if (item.id && item.id === "camera") {
      cameraParam = {
        position: item.pos,
        rotation: item.rot,
        fov: 90,
      };
    } else {
      const actualProduct: any = products.find((pr: any) => pr.id === item.id);
      productsArr.push({
        position: item.pos,
        rotation: item.rot,
        scale: item.scale,
        id: actualProduct.id,
        resource: actualProduct.resource,
        mountPoint: actualProduct.mountPoint,
        glbBundle: actualProduct.glbBundle,
      });
    }
  });
  result.products = productsArr;
  result.camera = cameraParam;

  return result;
}
