export class UrlService {
  /**
   * Метод для отримання поточного URL із window
   * @returns Поточний URL як рядок
   */
  static getCurrentUrl(): string {
    try {
      return window.location.href;
    } catch (error) {
      console.error("Unable to access current URL:", error);
      throw new Error("Failed to retrieve current URL.");
    }
  }

  /**
   * Метод для отримання сегментів шляху з URL
   * @param url - Вхідний URL
   * @returns Масив сегментів шляху
   */
  static getPathSegments(url: string): string[] {
    return new URL(url).pathname.split("/").filter(Boolean);
  }

  /**
   * Метод для отримання projectID із поточного URL
   * @returns projectID як число або null
   */
  static getProjectIDFromUrl(): number | null {
    try {
      const url = this.getCurrentUrl();
      const pathSegments = this.getPathSegments(url);
      const projectID = pathSegments.find((segment) => !isNaN(Number(segment)));
      console.log(`Project ID, successfully received: ${projectID}`);
      return projectID ? parseInt(projectID, 10) : null;
    } catch (error) {
      console.error("Error extracting projectID from URL:", error);
      return null;
    }
  }
}
